import { createSlice } from '@reduxjs/toolkit';

import type { GetFeedsResponse, Feed, StatusEntity } from '@models';
import { nullStatusEntity } from '@models';

import { createEntityAction, getEntityActions } from '../common';

const entities = {
  fetchFeeds: createEntityAction<void, GetFeedsResponse>({
    stateKey: 'feeds',
    servicePath: () => `/service/system/feeds`,
  }),
};

export const { fetchFeeds } = getEntityActions(entities);

export type FeedsState = StatusEntity<Feed[]>;

export const initialState: FeedsState = nullStatusEntity;

const feedsSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFeeds.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchFeeds.fulfilled, (state, action) => {
        state.status = 'ready';
        state.data = action.payload.body.data;
      })
      .addCase(fetchFeeds.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.payload || action.error;
      });
  },
});

export default feedsSlice.reducer;
