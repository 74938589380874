import type { Service } from '@models';
import {
  SERVICE_AVAILABLE,
  SERVICE_ORPHANED,
  SERVICE_REGISTERED,
  SERVICE_UNAVAILABLE,
} from '@models';

/**
 * Returns true when the service state properties indicate that it is up and
 * available.
 */
export const isServiceUp = ({ status, statusMessage }: Service) =>
  status === true && statusMessage === SERVICE_AVAILABLE;

/**
 * Returns true when the service state properties indicate that it is orphaned.
 */
export const isServiceOrphaned = ({ status, statusMessage }: Service) =>
  !status && statusMessage === SERVICE_ORPHANED;

/**
 * Returns true when the service state properties indicate that it is down.
 */
export const isServiceDown = ({ status, statusMessage }: Service) =>
  !status &&
  (statusMessage === SERVICE_UNAVAILABLE ||
    statusMessage === SERVICE_REGISTERED);
