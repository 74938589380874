import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { Button, Icon, Label, Menu, MenuItem, Popup } from 'semantic-ui-react';
import request from 'superagent';

import { getUserPermsSync } from '@/rbac';
import { logout } from '@redux/actions/action-auth';
import { useAppDispatch } from '@redux/hooks';
import { useSystemHealth } from '@redux/system/health.slice';
import arePermsGoodForRoute from '@routes/arePermsGoodForRoute';
import routeMetadata from '@routes/routeMetadata';
import { FeatureFlagContext } from '@shared/components/FeatureFlag';
import Link from '@shared/components/Link';
import StatusIcon, {
  healthStatusVariants,
} from '@shared/components/StatusIcon';

// eslint-disable-next-line
import s from './Sidenav.scss';
/**
 * Simple wrapper utility that uses a test to determine if an element should be
 * wrapped in an outer-element or not—this avoids all the conditional element
 * assignments that might otherwise be required in order to do this.
 * @param test {Boolean}
 * @param outer {JSX.Element} An outer element that is used if the test is true
 * @param children {JSX.Element} An inner element to be (optionally) wrapped
 * @returns {JSX.Element} A wrapped or unwrapped element
 */
const OptionalWrapper = ({ test, outer, children }) =>
  test ? outer(children) : children;

/**
 * This operation generates the popup that displays the last visited URL within
 * the Image Selection / Analysis component (if available).
 * @param props {Object} The component props object provided by the HOC
 * @param trigger
 * @returns {JSX.Element|null}
 */
const imageDataPopup = (props, trigger) => {
  const { image } = props;

  const { routeInfo = {} } = image.selection;
  const routeInfoArr = [
    routeInfo.registry,
    routeInfo.repository,
    routeInfo.tag,
    routeInfo.id ? `${routeInfo.id.substring(0, 13)}...` : undefined,
  ];

  const pathStr = routeInfoArr.reduce((prev, curr) => {
    if (curr && !prev) {
      prev = curr;
    } else if (curr) {
      prev = `${prev}/${curr}`;
    }
    return prev;
  }, false);

  return pathStr ? (
    <Popup
      key={routeInfo.path}
      position="right center"
      hoverable
      flowing
      className="animate__animated animate__fadeIn"
      offset={[0, 10]}
      content={
        <Link to={`${routeInfo.path}`}>
          <Icon name="tag" color="teal" />
          <span>
            Return to&nbsp;
            <strong>{pathStr}</strong>
          </span>
        </Link>
      }
      trigger={trigger}
    />
  ) : null;
};

/**
 * This operation generates the popup that displays the last visited URL within
 * the Applications component (if available).
 * @param props {Object} The component props object provided by the HOC
 * @param trigger
 * @returns {JSX.Element|null}
 */
const appDataPopup = (props, trigger) => {
  const { applications } = props;
  const { selected = {} } = applications.viewProps;

  const pathStr = selected.application
    ? `${selected.applicationName}@${selected.versionName}`
    : false;

  return pathStr ? (
    <Popup
      key={selected.application}
      position="right center"
      hoverable
      flowing
      className="animate__animated animate__fadeIn"
      offset={[0, 10]}
      content={
        <Link to={`/applications/${selected.application}/${selected.version}`}>
          <Icon name="tag" color="teal" />
          <span>
            Return to&nbsp;
            <strong>{pathStr}</strong>
          </span>
        </Link>
      }
      trigger={trigger}
    />
  ) : null;
};

/**
 * This operation generates the popup that provides the list of all available
 * policies within the account (if this data is available).
 * @param props {Object} The component props object provided by the HOC
 * @param trigger
 * @returns {null}
 */
const policyDataPopup = (props, trigger) => {
  let pContent = null;

  // Grab the policy data list (if present and populated)
  let policyData = props.policy?.manager?.data;
  policyData =
    policyData instanceof Array && policyData.length
      ? _orderBy(policyData, ['name'], ['asc'])
      : false;

  if (policyData) {
    // Always keep the active policy at the top of the dropdown
    pContent = policyData.sort((a, b) => (a.active ? -1 : b.active ? 1 : 0));

    pContent = pContent.map(pItem => (
      <div key={pItem.policy_id}>
        <Link
          className={s.btnPopupPolicyItmLink}
          to={`/policy/${pItem.policy_id}`}
          style={
            props.params && props.params.policyId === pItem.policy_id
              ? {
                  color: 'rgba(0,0,0,.87)',
                  cursor: 'default',
                  fontWeight: 700,
                }
              : null
          }
        >
          <div>{pItem.name}</div>
          {pItem.active ? (
            <Label
              style={{
                marginLeft: '0.5rem',
                minWidth: 'max-content',
              }}
              color="green"
              size="mini"
            >
              Active
            </Label>
          ) : null}
        </Link>
      </div>
    ));

    pContent = (
      <Popup
        key="policyPopup"
        position="right center"
        className="animate__animated animate__fadeIn"
        hoverable
        flowing
        content={pContent}
        trigger={trigger}
        offset={[0, 10]}
      />
    );
  }

  return pContent;
};

/**
 * Simple check to see if the report service is available and active.
 * @param props {Object} The component props object provided by the HOC
 * @returns {boolean}
 */
const isReportServiceGood = props => {
  const { isReportsEnabledAndActive } = props.app.healthCheck;
  return (
    isReportsEnabledAndActive?.enabled && isReportsEnabledAndActive?.active
  );
};

/**
 * Simple check to see if the current pathname differs from the route path
 * @param path {string} The configured route path such as '/applications'
 * @returns {boolean}
 */
const isPathDifferent = path =>
  process.env.BROWSER && window.location.pathname !== path;

/**
 * Main composition factory for each button within the main sub-navigation area.
 * @param props {Object} The component props object provided by the HOC
 * @param metadata Navigation bar metadata provided by the route configuration
 * @returns {JSX.Element}
 */
const navBtnBuilder = (props, metadata, featureFlags) => {
  const { auth } = props;
  const { account } = auth;

  return routeMetadata.reduce((prev, curr) => {
    const { navbar, path, featureFlag } = curr;

    const featureAvailable = !featureFlag || featureFlags.includes(featureFlag);
    if (navbar && navbar.show !== false && featureAvailable) {
      const { permissions, enable } = navbar;
      let enabled =
        enable ||
        arePermsGoodForRoute({
          permissions: getUserPermsSync({
            account,
            actions: permissions,
          }),
          route: curr,
        });

      if (metadata[navbar.name]?.enabled) {
        enabled = enabled && metadata[navbar.name]?.enabled;
      }

      const shouldLink = metadata[navbar.name]?.shouldLink?.(curr.path) ?? true;

      let innerEl = (
        <span className={s.navBtnPrefix}>
          <Icon
            color={props.active === navbar.title ? 'teal' : 'grey'}
            className={classNames(
              s[navbar.icon] || navbar.icon,
              props.active === navbar.title ? 'active' : '',
              s.navBtnIcon,
              s[`${navbar.name}Icon`],
            )}
          />
          <span>{navbar.title}</span>
        </span>
      );

      // Wrap the element in a popup if one is specified
      if (
        enabled &&
        metadata[navbar.name]?.popup &&
        props.active !== navbar.title
      ) {
        innerEl = metadata[navbar.name]?.popup(props, innerEl) || innerEl;
      }

      const el = (
        <div key={navbar.title}>
          <OptionalWrapper
            test={enabled && shouldLink}
            outer={children => <Link to={path}>{children}</Link>}
          >
            <MenuItem
              active={props.active === navbar.title}
              disabled={!enabled}
              className={s.navMenuItem}
              role="button"
            >
              <div className={s.navBtn}>
                {innerEl}
                {props.active !== navbar.title && metadata[navbar.name]?.suffix
                  ? metadata[navbar.name].suffix(props)
                  : null}
              </div>
            </MenuItem>
          </OptionalWrapper>
        </div>
      );

      prev.push(el);
    }

    return prev;
  }, []);
};

// --- MAIN --------------------------------------------------------------------

/**
 * @param props {Object} The component props object provided by the HOC
 * @returns {JSX.Element|null}
 */
const Sidenav = props => {
  const dispatch = useAppDispatch();
  const {
    enrichInventoryView,
    auth: { isAuthenticated },
  } = props;

  const { status, updateHealthData } = useSystemHealth();

  useEffect(() => {
    if (isAuthenticated) updateHealthData();
  }, [isAuthenticated, updateHealthData]);

  const featureFlags = useContext(FeatureFlagContext);

  const systemStatusIcon = (
    <span className={classNames(s.navBtnSuffix)}>
      <StatusIcon
        variant={healthStatusVariants[status]}
        style={{ opacity: 1, lineHeight: '1rem', margin: 0 }}
      />
    </span>
  );

  const menuItemList = navBtnBuilder(
    props,
    {
      artifacts: { popup: imageDataPopup },
      applications: { popup: appDataPopup },
      policy: { popup: policyDataPopup },
      system: { suffix: () => systemStatusIcon },
      reports: { enabled: isReportServiceGood },
      inventory: {
        enabled: enrichInventoryView ? isReportServiceGood : undefined,
        shouldLink: isPathDifferent,
      },
    },
    featureFlags,
  );

  return isAuthenticated ? (
    <div className={classNames(s.root, props.sideNavOpen ? s.open : s.closed)}>
      <Menu vertical compact fluid borderless className={s.navMenu}>
        {menuItemList}
      </Menu>
      <div className={s.navFooter}>
        <div className={s.logoutWrapper}>
          <Button
            className={classNames(
              s.navBtn,
              props.sideNavOpen ? '' : 'animate__animated animate__fadeIn',
            )}
            onClick={() => {
              request
                .post('/service/logout')
                .send({ action: 'setLogoutSuccess' })
                .then(resp => {
                  const {
                    data: { user, account },
                  } = resp.body;
                  dispatch(logout({ user, account }));
                });
            }}
          >
            <Icon color="grey" name="log out" className={s.navBtnIcon} />
            <span>Log Out</span>
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export const propTypes = {
  app: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  enrichInventoryView: PropTypes.bool.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
};

Sidenav.propTypes = propTypes;

export default withStyles(s)(Sidenav);
