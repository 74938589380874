import {
  Icon,
  type IconProps,
  type SemanticCOLORS,
  type SemanticICONS,
} from 'semantic-ui-react';

import type { HealthStatus } from '@models';

export type StatusVariant =
  | 'error'
  | 'info'
  | 'neutral'
  | 'success'
  | 'warning';

export const colorsByStatus: Record<StatusVariant, SemanticCOLORS> = {
  error: 'red',
  info: 'blue',
  neutral: 'grey',
  success: 'green',
  warning: 'yellow',
};

export const iconsByStatus: Record<StatusVariant, SemanticICONS> = {
  error: 'exclamation circle',
  info: 'info circle',
  neutral: 'question circle',
  success: 'check circle',
  warning: 'exclamation triangle',
};

export const healthStatusVariants: Record<HealthStatus, StatusVariant> = {
  healthy: 'success',
  degraded: 'warning',
  unhealthy: 'error',
};

export interface StatusIconProps extends IconProps {
  /**
   * The type of status. Determines the color and icon, unless overridden.
   */
  variant?: StatusVariant;
}

/**
 * A convenience component to render a preset status-based icon.
 */
const StatusIcon = ({
  name,
  variant = 'neutral',
  ...iconProps
}: StatusIconProps) => (
  <Icon
    role="presentation"
    name={name || iconsByStatus[variant]}
    color={colorsByStatus[variant]}
    {...iconProps}
  />
);

export default StatusIcon;
