import { createSlice } from '@reduxjs/toolkit';

import type { GetServicesResponse, Service, StatusEntity } from '@models';
import { nullStatusEntity } from '@models';

import { createEntityAction, getEntityActions } from '../common';

const entities = {
  fetchServices: createEntityAction<void, GetServicesResponse>({
    stateKey: 'services',
    servicePath: () => `/service/system/services`,
  }),
};

export const { fetchServices } = getEntityActions(entities);

export type ServicesState = StatusEntity<Service[]>;

export const initialState: ServicesState = nullStatusEntity;

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchServices.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.status = 'ready';
        state.data = action.payload.body.data;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.status = 'error';
        state.error = action.payload || action.error;
      });
  },
});

export default servicesSlice.reducer;
