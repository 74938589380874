// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P3UJ1 .dnIGv i,.P3UJ1 .dnIGv span{color:rgba(0,0,0,.87)}.nPYSN{font-size:15.2px;font-size:.95rem}.nPYSN label:after{background-color:#1cb5ac!important;opacity:.5!important}.nPYSN label{color:#999!important}.nPYSN label:before{opacity:1;transition:opacity .25s ease-in-out}.Nm4zu label:before{opacity:.5;transition:opacity .25s ease-in-out}.Nm4zu label:after{opacity:1!important}.nkbDX{margin-left:2.25rem!important}.nkbDX>div{margin-top:.5rem}.ZGaWe{border-radius:.125rem;overflow:hidden;padding-right:.1rem;text-overflow:ellipsis}.ZGaWe .YdzbW{background-color:rgba(28,181,172,.125);border-radius:.125rem;font-family:monospace;font-size:15.2px;font-size:.95rem;padding:.375rem .5rem}.Ou6NQ{align-items:center!important;border:none!important;color:rgba(0,0,0,.87)!important;cursor:pointer;display:flex!important;height:32px!important;height:2rem!important;margin:0 0 0 .75rem!important;position:relative!important}.dMhUv{align-items:center;background-color:#3682e0!important;border:0!important;color:#fff!important;display:flex!important;font-weight:100!important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `P3UJ1`,
	"actionBtnGrp": `dnIGv`,
	"checkbox": `nPYSN`,
	"checked": `Nm4zu`,
	"helpList": `nkbDX`,
	"editLabels": `ZGaWe`,
	"label": `YdzbW`,
	"readOnlyLabel": `Ou6NQ`,
	"header": `dMhUv`
};
module.exports = ___CSS_LOADER_EXPORT___;
