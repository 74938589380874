// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AIWL6{border-bottom:1px solid rgba(0,0,0,.1);display:flex;justify-content:space-between;padding:.5rem .7rem}.AIWL6 .o1ElS,.AIWL6 .nwUn4{align-items:center;display:flex;flex:1 0;justify-content:center}.AIWL6 .o1ElS .ui.action.input:not([class*="left action"]):not(.m3Nhg)>input{border-bottom-right-radius:4.571px!important;border-bottom-right-radius:.2857rem!important;border-right-color:rgba(34,36,38,.15)!important;border-top-right-radius:4.571px!important;border-top-right-radius:.2857rem!important}.AIWL6 .o1ElS .ui.action.input:not([class*="left action"]):not(.m3Nhg)>input:focus{border-right-color:#85b7d9!important}.AIWL6 .nf7gX{display:flex;flex:1 0;justify-content:flex-end}.AIWL6 div *{outline:none}.AIWL6 .qUpKA{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AIWL6`,
	"filter": `o1ElS`,
	"pageSize": `nwUn4`,
	"noRightBorder": `m3Nhg`,
	"pagination": `nf7gX`,
	"hide": `qUpKA`
};
module.exports = ___CSS_LOADER_EXPORT___;
