// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XA3Jx{background-color:unset!important;color:rgba(0,0,0,.6)!important}.XA3Jx:focus,.XA3Jx:hover{background-color:#dededf!important;color:rgba(0,0,0,.6)!important}.BioQG{background-color:unset!important;box-shadow:none!important;color:#fff!important}.BioQG:focus,.BioQG:hover{background-color:#2f3335!important;color:#fff!important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `XA3Jx`,
	"dark": `BioQG`
};
module.exports = ___CSS_LOADER_EXPORT___;
