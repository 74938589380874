import n from 'animate.css';
import ApolloClient from 'apollo-boost';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState, useEffect, cloneElement, useRef, useMemo } from 'react';
import { ApolloProvider } from 'react-apollo';
import a from 'react-datepicker/dist/react-datepicker.css';
import ReactDOMServer from 'react-dom/server';
import { ToastContainer } from 'react-toastify';
import t from 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Grid, Icon, Segment } from 'semantic-ui-react';

import history from '@/history';
import o from '@/../public/font-mfizz/font-mfizz.css';
import e from '@/../public/pace/pace-theme-minimal.css';
import i from '@/../public/pdf/roboto.css';
import r from '@/../public/prism/prism.css';
import h from '@/../public/react-table/react-table.css';
import c from '@/../public/semantic/semantic.min.css';
import { appStateReset, setLogoutSuccess } from '@redux/actions/action-auth';
import {
  startAppConnectionCheck,
  startHealthCheck,
} from '@redux/actions/action-health';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import darkModeProps from '@shared/components/DarkModeToggle/darkModeProps.json';
import Err from '@shared/components/Err';
import AppTour from '@components/AppTour';
import Footer from '@components/Footer';
import HealthErr from '@components/HealthErr';
import Sidenav from '@components/Sidenav';
import Topnav from '@components/Topnav';

import ModalDimmer from './ModalDimmer';
import serviceFetchCycler from './serviceFetchCycler';
import socketListenerInit from './socketListenerInit';
import s from './Layout.scss';

const USER_TIMEZONE = moment.tz.guess();
const BG_CYCLE_ELAPSE = 300000; // Time (in ms) of each service fetch cycle
const SIDENAV_OPEN_WIDTH = '20rem';
const SIDENAV_CLOSED_WIDTH = '4.05rem';

const isBrowser = process.env.BROWSER;

// What to display if JS is disabled in the browser
const NoScript = el => {
  const staticMarkup = ReactDOMServer.renderToStaticMarkup(el.children);
  return <noscript dangerouslySetInnerHTML={{ __html: staticMarkup }} />; // eslint-disable-line react/no-danger
};

let sideNavStorageStr = 'true';

if (isBrowser) {
  sideNavStorageStr = localStorage.getItem('sidenav');
}

// For a few state items, we need to capture the previous state of an item
// before its state hook updates
const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable and
  // can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// Main view component
const Layout = props => {
  const {
    apiUrl,
    children,
    customLinks,
    dbVersion,
    engineVersion,
    isWebSocketOnly,
    routeMask,
    uiVersion,
    isEngineHealthy,
    enrichInventoryView,
    store,
  } = props;

  let { account, isAuthenticated, permissions, whatsNew } = props;

  // Pointer to the main view element payload
  let mainEl;

  // Redux store dispatch function
  const dispatch = useAppDispatch();

  // Create a state object for the side navigation panel
  const [sideNavOpen, setSideNavOpen] = useState(true);

  // Create a state object for the license that can easily be updated
  const [isLicenseGood, setIsLicenseGood] = useState(props.isLicenseGood);

  // Pointer to the last time the service fetch cycle ran
  const [serviceFetchCyclerLastRun, setServiceFetchCyclerLastRun] = useState(
    () => moment().tz(USER_TIMEZONE).toISOString(),
  );

  // Create a state object for the availability of the Dark Reader extension
  const [isDarkReaderAvailable, setIsDarkReaderAvailable] = useState(false);

  // Create a new instance of the Apollo GraphQL client and add to component
  const [apolloClient] = useState(
    () =>
      new ApolloClient({
        uri: `${apiUrl}/reports/graphql`,
      }),
  );

  // Pointers to the current state of the application
  const auth = useAppSelector(state => state.auth);
  const app = useAppSelector(state => state.app);

  const clientFreshlyLoggedIn =
    usePrevious(auth.isAuthenticated) === false && auth.isAuthenticated;

  const clientFreshlyLoggedOut =
    usePrevious(auth.isAuthenticated) === true && !auth.isAuthenticated;

  // Determine the authentication state of the user. Seems simple at first but
  // have to remember this is an isomorphic app, so the client-side state can be
  // different from the initial `isAuthenticated` prop provided by the server
  // on initial load. So:
  // - We check to see if the Redux state is freshly logged out (was `true` and
  //   is now `false`), in which case `isAuthenticated` is `false`
  // - If the Redux state is not freshly logged out, we check the Redux state
  //   of the `auth.isAuthenticated` prop to determine the current state
  // - If the Redux auth state is `false` it might just be because it's unset
  //   after a page refresh, so we check the initial `isAuthenticated` prop to
  //   determine the current state
  isAuthenticated = clientFreshlyLoggedOut
    ? false
    : auth.isAuthenticated || props.isAuthenticated;

  // This condition handles a page recomposition on the server after a user has
  // logged out and then refreshed the page. It effectively resets the Redux
  // state so that all authentication indicators are `false`. Note that if
  // `props.isAuthenticated` is `false` on the *server* then the user *must* be
  // logged. out.
  if (!isBrowser && !props.isAuthenticated && auth.isAuthenticated) {
    isAuthenticated = false;
    dispatch(setLogoutSuccess());
  }

  // If this is the browser context that includes Cypress, make the store
  // accessible
  if (isBrowser && window.Cypress) {
    window.store = store;
  }

  // If the user is freshly logged out on the client, the easiest way to re-sync
  // the `props.isAuthenticated` state is to redirect the user to the home page
  useMemo(() => {
    if (clientFreshlyLoggedOut && props.isAuthenticated) {
      history.push('/');
    }
  }, [clientFreshlyLoggedOut]);

  // If the user is freshly logged in on the client, we need to set the state of
  // the side navigation panel based on the user's last interaction with it
  useMemo(() => {
    if (isBrowser && clientFreshlyLoggedIn) {
      sideNavStorageStr = localStorage.getItem('sidenav');
      setSideNavOpen(
        sideNavStorageStr === null ? true : sideNavStorageStr === 'true',
      );
    }
  }, [clientFreshlyLoggedIn]);

  // This effect runs on login, assuming all the conditional criteria are met.
  // It resets the Redux state to the initial state, then sets the new state
  // based on the account and permissions provided by the server. It also
  // initializes the `whatsNew` state based on the `uiVersion` prop. Does the
  // opposite if the criteria is not met; it resets the Redux state to the
  // initial state.
  useMemo(() => {
    if (
      !clientFreshlyLoggedOut &&
      isAuthenticated &&
      isLicenseGood?.isBeforeExpiration &&
      account
    ) {
      dispatch(
        appStateReset({
          app: { ...app },
          ...(account.baseState || {}),
          auth: {
            account,
            dbVersion,
            engineVersion,
            errorMsg: false,
            isAuthenticated: true,
            isFetching: false,
            loginError: false,
            logoutError: false,
            permissions,
            prevAuthState: false,
            uiVersion,
          },
        }),
      );

      ({ whatsNew } = app);
      whatsNew.isWhatsNewCtrlShown = uiVersion !== account.uiVersionPrev;

      if (isBrowser) {
        sideNavStorageStr = localStorage.getItem('sidenav');
        setSideNavOpen(
          sideNavStorageStr === null ? true : sideNavStorageStr === 'true',
        );
      }
    } else if (isBrowser && clientFreshlyLoggedOut && !isAuthenticated) {
      dispatch(setLogoutSuccess());
    }
  }, []);

  // Add and remove operations based on authentication state
  useEffect(() => {
    if (isBrowser) {
      // Instantiate the websocket client binding if it's not already present
      if (!window.sock && window.io && isLicenseGood?.isBeforeExpiration) {
        // Initialize all the various socket message listeners
        window.sock = socketListenerInit({
          props,
          isWebSocketOnly,
          setIsLicenseGood,
          startAppConnectionCheck,
          startHealthCheck,
          store,
        });
      }

      if (isAuthenticated && isLicenseGood?.isBeforeExpiration) {
        // This condition adds the user- and account-specific socket channels
        // after authentication, or restores them after a page refresh within an
        // authenticated session
        if (account?.sessionID) {
          // Room scoped to the session ID (private to this user)
          window.sock.emit('addToRoom', account.sessionID);
          // Room scoped to the account name (private to this account)
          window.sock.emit('addToRoom', `account-${account.accountname}`);
          if (account.isAdmin) {
            // Room scoped to all administrators (users with the system-admin role)
            window.sock.emit('addToRoom', `system-admins`);
          }
        }

        // Initialize Prism if it's not already loaded
        if (!window.Prism) {
          window.Prism = require('@/../public/prism/prism'); // eslint-disable-line global-require
        }

        // Configure the Pace progress bar and then initialize if it's not
        // already present
        if (!window.Pace) {
          window.paceOptions = {
            elements: false,
            restartOnRequestAfter: false,
          };
          require('@/../public/pace/pace.min'); // eslint-disable-line global-require
        }

        // Kick off the background service fetch cycle if it's not already
        // running. Store the cycler ID in the global object—this allows the
        // cycle to be cleared after a component hot-update in development mode.
        if (!window.App.serviceFetchCyclerId) {
          window.App.serviceFetchCyclerId = serviceFetchCycler({
            cycle: BG_CYCLE_ELAPSE,
            setServiceFetchCyclerLastRun,
            store,
          });
        }
      } else {
        // Clear any existing IDs for the image fetch cycler (this should only
        // be required in the development context after a hot-update)
        if (window.App.serviceFetchCyclerId) {
          clearInterval(window.App.serviceFetchCyclerId);
          window.App.serviceFetchCyclerId = false;
        }

        if (window.Pace) {
          window.Pace.stop();
        }
      }
    }
  }, [isAuthenticated, isLicenseGood?.isBeforeExpiration]);

  // Initialize the Dark Reader extension if it's not already loaded
  useEffect(() => {
    if (isBrowser) {
      if (!window.App.darkReader) {
        let darkModeState = localStorage.getItem('darkmode');

        if (!darkModeState) {
          darkModeState =
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
              ? 'true'
              : 'false';
        }

        window.App.darkReader = require('darkreader'); // eslint-disable-line global-require
        setIsDarkReaderAvailable(true);
        const { darkReader } = window.App;
        if (darkModeState === 'true') {
          darkReader.enable(darkModeProps);
        } else if (darkModeState === 'false') {
          darkReader.disable();
        }
      }
    }

    // Remove the socket listeners if the component unmounts
    return () => {
      if (isBrowser && window.sock?.removeAllListeners) {
        window.sock.removeAllListeners('connect');
        window.sock.removeAllListeners('connect_error');
      }
    };
  }, []);

  // Provide an additional class to the layout wrapper if dark mode is enabled
  const darkClass = isDarkReaderAvailable
    ? window.App.darkReader.isEnabled()
      ? 'dark'
      : ''
    : '';

  if (isLicenseGood) {
    if (isLicenseGood.isBeforeExpiration) {
      ({ account } = auth);
      permissions =
        isAuthenticated && permissions ? permissions : auth.permissions;

      let defaultBg = s.default;

      if (isAuthenticated && account) {
        defaultBg = isAuthenticated && account.isAdmin ? s.admin : s.standard;

        defaultBg =
          account.context?.accountname !== account.accountname
            ? s.switched
            : defaultBg;
      }

      const minutesUntilNextCycle = moment(serviceFetchCyclerLastRun).add(
        BG_CYCLE_ELAPSE / 1000 / 60,
        'm',
      );

      const bgCycleNextRun = {
        cycle: BG_CYCLE_ELAPSE,
        label: minutesUntilNextCycle.fromNow(),
        value: minutesUntilNextCycle,
      };

      // Framer motion animations for the left and right panels
      const framerProps = {
        leftPanel: isAuthenticated
          ? {
              initial: {
                width: sideNavOpen ? SIDENAV_OPEN_WIDTH : SIDENAV_CLOSED_WIDTH,
              },
              animate: {
                width: sideNavOpen ? SIDENAV_OPEN_WIDTH : SIDENAV_CLOSED_WIDTH,
              },
              transition: {
                duration: clientFreshlyLoggedIn ? 0 : 0.5,
                type: 'spring',
                bounce: 0,
              },
            }
          : {
              initial: { width: 0 },
              animate: { width: 0 },
              transition: { duration: 0 },
            },
        rightPanel: isAuthenticated
          ? {
              initial: {
                marginLeft: sideNavOpen
                  ? SIDENAV_OPEN_WIDTH
                  : SIDENAV_CLOSED_WIDTH,
              },
              animate: {
                marginLeft: sideNavOpen
                  ? SIDENAV_OPEN_WIDTH
                  : SIDENAV_CLOSED_WIDTH,
              },
              transition: {
                duration: clientFreshlyLoggedIn ? 0 : 0.5,
                type: 'spring',
                bounce: 0,
              },
            }
          : {
              initial: { marginLeft: 0 },
              animate: { marginLeft: 0 },
              transition: { duration: 0 },
            },
      };

      mainEl = (
        <div id="layout" className={classNames(s.root, darkClass)}>
          {!isAuthenticated ? (
            <div id="bgColor" className={classNames(s.bg, defaultBg)} />
          ) : null}
          {!isAuthenticated ? <div id="bgImage" className={s.doodle} /> : null}
          <div className={s.contentWrapper}>
            <NoScript>
              <div className={s.noscript}>
                <Container>
                  <Grid centered columns={1}>
                    <Grid.Column
                      mobile={15}
                      tablet={12}
                      computer={10}
                      largeScreen={8}
                      widescreen={8}
                    >
                      <Segment.Group className="animate__animated animate__fadeIn">
                        <Segment
                          className="animate__animated animate__fadeIn"
                          style={{ padding: '2rem' }}
                        >
                          <h1>We&#39;re sorry&hellip;</h1>
                          <p>
                            &hellip;but JavaScript is required to use the
                            Anchore Enterprise UI Client application. If you
                            think scripts&nbsp;
                            <em>should</em> be enabled on your browser,&nbsp;
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://www.enable-javascript.com"
                            >
                              this site
                            </a>
                            &nbsp;may help.
                          </p>
                          <p>
                            If you&#39;re still having problems, contact us
                            at&nbsp;
                            <a href="mailto:support@anchore.com">
                              support@anchore.com
                            </a>
                            &nbsp;and we&#39;ll do our best to assist.
                          </p>
                          <blockquote>
                            <p>
                              <em>
                                I have not failed. I have just found 10,000 ways
                                that won&#39;t work.
                              </em>
                            </p>
                            <sub>—Thomas A. Edison</sub>
                          </blockquote>
                          <div className={s.logoSmall} />
                        </Segment>
                        <span className={s.nuke} />
                      </Segment.Group>
                    </Grid.Column>
                  </Grid>
                </Container>
              </div>
            </NoScript>
            <Topnav
              active={children.props.title}
              bgCycleElapse={BG_CYCLE_ELAPSE}
              customLinks={customLinks}
              isLicenseGood={isLicenseGood}
              permissions={permissions}
              uiVersion={uiVersion}
              whatsNew={whatsNew}
            />
            <div className={classNames(s.boundingPanel)}>
              {isAuthenticated ? (
                <AnimatePresence initial={false}>
                  <motion.div
                    className={classNames(s.leftPanel)}
                    {...framerProps.leftPanel}
                  >
                    <Button
                      compact
                      size="tiny"
                      onClick={() =>
                        setSideNavOpen(prev => {
                          const sideNavState = !prev;
                          localStorage.setItem(
                            'sidenav',
                            sideNavState.toString(),
                          );
                          return !prev;
                        })
                      }
                      aria-label="toggle sidebar"
                      style={{
                        zIndex: 2,
                        position: 'relative',
                        margin: 0,
                        borderRadius: 0,
                      }}
                    >
                      <Icon
                        name={
                          sideNavOpen
                            ? 'angle double left'
                            : 'angle double right'
                        }
                        style={{ margin: 0 }}
                      />
                    </Button>
                    <Sidenav
                      active={children.props.title}
                      params={children.props.params}
                      sideNavOpen={sideNavOpen}
                      enrichInventoryView={enrichInventoryView}
                      permissions={permissions}
                      routePermission={children.props.routePermission}
                      pathname={children.props.pathname}
                      base={children.props.base}
                    />
                  </motion.div>
                </AnimatePresence>
              ) : null}
              <AnimatePresence initial={false}>
                <motion.div {...framerProps.rightPanel}>
                  {cloneElement(children, {
                    ...(children.props || {}),
                    apolloClient,
                    bgCycleNextRun,
                    isAuthenticated,
                    isEngineHealthy,
                    isLicenseGood,
                    permissions,
                  })}
                </motion.div>
              </AnimatePresence>
            </div>
            <Footer
              marginLeft={
                sideNavOpen ? SIDENAV_OPEN_WIDTH : SIDENAV_CLOSED_WIDTH
              }
            />
            <HealthErr />
            <ToastContainer />
            {isAuthenticated && <AppTour routeMask={routeMask} />}
          </div>
        </div>
      );
    } else {
      mainEl = (
        <div id="layout" className={classNames(s.root, darkClass)}>
          <div className={classNames(s.bg, s.error)} />
          <div className={s.doodle} />
          <div
            style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Container>
              <Err
                header={
                  <span>
                    Your Anchore Enterprise
                    {isLicenseGood.type === 'trial' ? ' trial ' : ' '}
                    license has expired&hellip;
                  </span>
                }
                image="robot_flame.svg"
                imageSize="medium"
                headerColor="red"
                base
                message={
                  <>
                    <p>
                      For instructions on how to renew your license please refer
                      to the&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://docs.anchore.com"
                      >
                        documentation
                      </a>
                      .
                    </p>
                    <p>
                      You can also&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://anchore.com/contact"
                      >
                        request full or trial license
                      </a>
                      &nbsp;or contact&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="mailto:support@anchore.com"
                      >
                        Anchore Support
                      </a>
                      .
                    </p>
                  </>
                }
              />
            </Container>
          </div>
          <ToastContainer />
        </div>
      );
    }
  } else {
    mainEl = (
      <div id="layout" className={classNames(s.root, s.bg, darkClass)}>
        <div className={classNames(s.bg, s.error)} />
        <div className={s.doodle} />
        <div
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Container>
            <Err
              header="No valid license detected&hellip;"
              image="robot_notfound.svg"
              imageSize="small"
              headerColor="orange"
              base
              message={
                <>
                  <p>
                    Anchore Enterprise Client requires a valid license to
                    operate.
                  </p>
                  <br />
                  <p>
                    For instructions on how to activate your license please
                    refer to the&nbsp;
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://docs.anchore.com"
                    >
                      documentation
                    </a>
                    .
                  </p>
                  <p>
                    You can also&nbsp;
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://anchore.com/contact"
                    >
                      request a full or trial license
                    </a>
                    &nbsp;or contact&nbsp;
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="mailto:support@anchore.com"
                    >
                      Anchore Support
                    </a>
                    .
                  </p>
                </>
              }
            />
          </Container>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <>
      <ApolloProvider client={apolloClient}>{mainEl}</ApolloProvider>
      <ModalDimmer />
      <div id="portalRoot" />
    </>
  );
};

export const propTypes = {
  account: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  apiUrl: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
  store: PropTypes.shape({}).isRequired,
  customLinks: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  dbVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  engineVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isAuthenticated: PropTypes.bool.isRequired,
  isEngineHealthy: PropTypes.bool.isRequired,
  isLicenseGood: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  isWebSocketOnly: PropTypes.bool.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  routeMask: PropTypes.string,
  uiVersion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  whatsNew: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
    .isRequired,
  enrichInventoryView: PropTypes.bool.isRequired,
};

Layout.propTypes = propTypes;

Layout.defaultProps = {
  apiUrl: undefined,
  children: null,
  customLinks: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  dbVersion: false,
  engineVersion: false,
  permissions: false,
  routeMask: '/',
  uiVersion: false,
};

export default withStyles(i, t, s, a, n, c, h, o, r, e)(Layout);
