import { createSelector, createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';

import type {
  FeedsHealth,
  HealthStatusBySystemComponent,
  ServicesHealth,
} from '@models';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import type { RootState } from '@redux/store';
import {
  createFeedsHealth,
  createServicesHealth,
  getFeedsHealth,
  getFeedsStatus,
  getServicesHealth,
  getServicesStatus,
  getSystemStatus,
} from '@utils/system';

import { fetchFeeds } from './feeds.slice';
import { fetchServices } from './services.slice';

export interface SystemHealthState {
  /**
   * Health statuses of core system components
   */
  statusByComponent: HealthStatusBySystemComponent;
  /**
   * Detailed health information for Services
   */
  services: ServicesHealth;
  /**
   * Detailed health information for Feeds
   */
  feeds: FeedsHealth;
}

export const initialState = {
  statusByComponent: {
    feeds: undefined,
    services: undefined,
  },
  services: createServicesHealth(),
  feeds: createFeedsHealth(),
} satisfies SystemHealthState as SystemHealthState;

const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFeeds.fulfilled, (state, action) => {
        const feedsHealth = getFeedsHealth(action.payload.body.data);

        state.statusByComponent.feeds = getFeedsStatus(feedsHealth);
        state.feeds = feedsHealth;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        const servicesHealth = getServicesHealth(action.payload.body.data);

        state.statusByComponent.services = getServicesStatus(servicesHealth);
        state.services = servicesHealth;
      });
  },
});

export const selectStatuses = (state: RootState) =>
  state.health.statusByComponent;

export const selectServicesHealth = (state: RootState) => state.health.services;

export const selectFeedsHealth = (state: RootState) => state.health.feeds;

/**
 * Derives the overall system status based on statuses of individual components.
 * This selector will only update consumers when the status map changes, to
 * prevent unnecessary re-renders when the health details update, which is quite
 * chatty due to the bgCycler.
 */
export const selectSystemStatus = createSelector(
  selectStatuses,
  statusByComponent => getSystemStatus(statusByComponent),
);

export const useSystemStatus = () => useAppSelector(selectSystemStatus);

export const useSystemHealth = () => {
  const dispatch = useAppDispatch();
  const status = useSystemStatus();
  const statusByComponent = useAppSelector(selectStatuses);

  const isFetchingHealthData = useAppSelector(state =>
    [state.services.status, state.feeds.status].includes('loading'),
  );

  /**
   * Re-fetches the latest data used to determine the system's health status.
   */
  const updateHealthData = useCallback(() => {
    if (isFetchingHealthData) return;

    dispatch(fetchFeeds());
    dispatch(fetchServices());
  }, []);

  return { status, statusByComponent, updateHealthData, isFetchingHealthData };
};

export default healthSlice.reducer;
