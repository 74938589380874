import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Divider, Form, Icon } from 'semantic-ui-react';

// eslint-disable-next-line postcss-modules/no-unused-class
import s from './DatePicker.scss';

const DatePicker = ({
  onChange,
  value,
  customInput,
  disabled,
  className,
  buttonProps,
  setCalendarOpen,
}) => {
  // Hooks to hold the date range and count values for offset dates
  const [dateOffsetCount, setDateOffsetCount] = useState(1);
  const [dateOffsetRange, setDateOffsetRange] = useState('w');
  const [dateOffsetDirection, setDateOffsetDirection] = useState(
    value && moment(value).isBefore() ? 'before' : 'from',
  );

  const displayedDate = value ? moment(value).toDate() : null;

  // Pointer to hold the reference to the calendar control internal API
  let calendarApi;

  return (
    <ReactDatePicker
      ref={calObj => {
        calendarApi = calObj;
      }}
      className={className}
      customInput={
        customInput || (
          <div className={s.root}>
            <Button icon labelPosition="right" basic fluid {...buttonProps}>
              <Icon name="calendar alternate outline" />
              <input
                className={classNames(s.buttonInput, 'darkInput')}
                value={displayedDate?.toString() || 'N/A'}
                onChange={() => undefined}
              />
            </Button>
          </div>
        )
      }
      onCalendarOpen={() => setCalendarOpen?.(true)}
      onCalendarClose={() => setCalendarOpen?.(false)}
      shouldCloseOnSelect={false}
      placeholderText="Enter a Value"
      disabled={disabled}
      openToDate={displayedDate}
      dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      timeFormat="HH:mm"
      withPortal
      portalId="portalRoot"
    >
      <div className={s.datePickerContentsWrapper}>
        <Button
          className={s.setDateBtn}
          color="blue"
          fluid
          onClick={() => {
            onChange(calendarApi.state.preSelection);
            calendarApi.setOpen(false);
          }}
        >
          Set Date
        </Button>
        <div className={s.offsetPickerWrapper}>
          <div className={s.fillerBlock} />
          <div>
            <Divider className={s.divider} vertical>
              Or
            </Divider>
          </div>
          <div className={s.formWrapper}>
            <Form>
              <Form.Input
                type="number"
                autoFocus
                value={dateOffsetCount || ''}
                onKeyDown={ev => {
                  if (ev.code === 'Enter' && dateOffsetCount) {
                    onChange(
                      moment().add(dateOffsetCount, dateOffsetRange).toDate(),
                    );
                    calendarApi.setOpen(false);
                  }
                }}
                onChange={(ev, data) => {
                  const dateOffsetCountInt = parseInt(data.value.trim(), 10);
                  if (
                    typeof dateOffsetCountInt === 'number' &&
                    dateOffsetCountInt > 0
                  ) {
                    setDateOffsetCount(dateOffsetCountInt);
                  } else if (!dateOffsetCountInt && dateOffsetCountInt !== 0) {
                    setDateOffsetCount(false);
                  }
                }}
              />
              <Form.Dropdown
                fluid
                selection
                options={[
                  {
                    key: 'minutes',
                    text: dateOffsetCount === 1 ? 'Minute' : 'Minutes',
                    value: 'm',
                  },
                  {
                    key: 'hours',
                    text: dateOffsetCount === 1 ? 'Hour' : 'Hours',
                    value: 'h',
                  },
                  {
                    key: 'days',
                    text: dateOffsetCount === 1 ? 'Day' : 'Days',
                    value: 'd',
                  },
                  {
                    key: 'weeks',
                    text: dateOffsetCount === 1 ? 'Week' : 'Weeks',
                    value: 'w',
                  },
                  {
                    key: 'months',
                    text: dateOffsetCount === 1 ? 'Month' : 'Months',
                    value: 'M',
                  },
                ]}
                value={dateOffsetRange}
                onChange={(ev, data) => {
                  setDateOffsetRange(data.value);
                }}
              />
              <Form.Dropdown
                fluid
                selection
                value={dateOffsetDirection}
                onChange={(ev, data) => {
                  setDateOffsetDirection(data.value);
                }}
                options={[
                  { key: 'before', text: 'Before Now', value: 'before' },
                  { key: 'from', text: 'From Now', value: 'from' },
                ]}
              />
            </Form>
            <Button
              color="blue"
              disabled={!dateOffsetCount}
              onClick={() => {
                onChange(
                  dateOffsetDirection === 'from'
                    ? moment().add(dateOffsetCount, dateOffsetRange).toDate()
                    : moment()
                        .subtract(dateOffsetCount, dateOffsetRange)
                        .toDate(),
                );
                calendarApi.setOpen(false);
              }}
            >
              Apply
            </Button>
            <div className={classNames(s.sadRobot, 'darkSVG')} />
          </div>
        </div>
      </div>
    </ReactDatePicker>
  );
};

DatePicker.defaultProps = {
  value: '',
  disabled: false,
  customInput: null,
  className: '',
  buttonProps: {},
  setCalendarOpen: null,
};

export const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  customInput: PropTypes.element,
  className: PropTypes.string,
  buttonProps: PropTypes.shape({}),
  setCalendarOpen: PropTypes.func,
};

DatePicker.propTypes = propTypes;

export default withStyles(s)(DatePicker);
