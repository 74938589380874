// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E60fA{height:100%;justify-content:center}.E60fA,.E60fA span{align-items:center;display:flex}.E60fA span button{background-color:transparent!important;margin:0 .25rem!important}.E60fA span button i.AzPu2{font-size:20px;font-size:1.25rem}.E60fA span .zTgBp{top:1.6px;top:.1rem}.E60fA span .zTgBp label:after{background-color:#1cb5ac!important;opacity:.5}.E60fA span .zTgBp label:before{opacity:.5;transition:opacity .25s ease-in-out}.E60fA span .TRjzm{top:1.6px;top:.1rem}.E60fA span .TRjzm label:before{background-color:hsla(0,0%,100%,.5)!important;opacity:.5;transition:opacity .25s ease-in-out}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `E60fA`,
	"sun": `AzPu2`,
	"toggleDark": `zTgBp`,
	"toggleLight": `TRjzm`
};
module.exports = ___CSS_LOADER_EXPORT___;
