import request from 'superagent';

import { getUserPerms } from '@/rbac';

import {
  SET_APP_STATE_RESET,
  SET_APP_STATE_UPDATE,
  SET_LOGIN_ERROR,
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGOUT_ERROR,
  SET_LOGOUT_PENDING,
  SET_LOGOUT_SUCCESS,
} from '../actionTypes';

export const appStateReset = (conf = {}) => ({
  type: SET_APP_STATE_RESET,
  conf,
});

export const appStateUpdate = (conf = {}) => ({
  type: SET_APP_STATE_UPDATE,
  conf,
});

export const setLoginPending = () => ({
  type: SET_LOGIN_PENDING,
});

export const setLoginSuccess = (conf = {}) => ({
  type: SET_LOGIN_SUCCESS,
  dbVersion: conf.dbVersion || false,
  engineVersion: conf.engineVersion || false,
  uiVersion: conf.uiVersion || false,
  account: conf.account || false,
  permissions: conf.permissions || false,
  isRequestedContextValid: !!conf.isRequestedContextValid,
  wasOnlyContextPathRequested: !!conf.wasOnlyContextPathRequested,
  isRequestedContextActuallyComponentPath:
    !!conf.isRequestedContextActuallyComponentPath,
  requestedPath: conf.requestedPath || '/',
});

export const setLoginError = errorMsg => ({
  type: SET_LOGIN_ERROR,
  errorMsg,
});

// The function called by the thunk middleware can return a value,
// that is passed on as the return value of the dispatch method.
export const login = props => dispatch => {
  // First dispatch: the app state is updated to inform
  // that the API call is starting.
  dispatch(setLoginPending());

  // In this case, we return a promise to wait for.
  // This is not required by thunk middleware, but it is convenient for us.
  return request
    .post('/service/login')
    .set('Content-Type', 'application/json')
    .send({
      username: props.username,
      password: props.password,
      authType: props.authType,
      pathname: window?.document?.location?.pathname,
    })
    .then(loginResp => {
      let resp;
      if (loginResp && loginResp.body && loginResp.body.status === 'success') {
        const { data } = loginResp.body;
        const { account } = data;

        resp = getUserPerms({ account, actions: props.actions }).then(
          permissions => {
            if (process.env.BROWSER) {
              if (window.App) {
                window.App.isAuthenticated = true;
                window.App.account = account;
                data.permissions = permissions;
              }
            }
            dispatch(appStateReset(account.baseState));
            return dispatch(setLoginSuccess(data));
          },
        );
      } else {
        resp = dispatch(setLoginError());
      }
      return resp;
    })
    .catch(err => {
      console.error(err);
      let resp;
      if (err.response?.body?.data) {
        resp = dispatch(setLoginError(err.response.body.data));
      } else {
        resp = dispatch(setLoginError());
      }
      return resp;
    });
};

export const setLogoutPending = () => ({
  type: SET_LOGOUT_PENDING,
});

export const setLogoutSuccess = () => ({
  type: SET_LOGOUT_SUCCESS,
});

export const setLogoutError = errorMsg => ({
  type: SET_LOGOUT_ERROR,
  errorMsg,
});

export const logout =
  (conf = {}) =>
  (dispatch, getState) => {
    let resp;
    const { server, user, account } = conf;

    // Generic utility for cleaning up the DOM
    const cleanUpDOM = () => {
      if (process.env.BROWSER) {
        if (window.App) {
          window.App.isAuthenticated = false;
          window.App.account = false;
        }
        if (window.sock) {
          const { sessionID, accountname } = getState().auth.account;
          if (sessionID) window.sock.emit('removeFromRoom', sessionID);
          if (accountname)
            window.sock.emit('removeFromRoom', `account-${accountname}`);
        }
      }
    };

    dispatch(setLogoutPending());

    if (server) {
      resp = request
        .post('/service/logout')
        .set('Content-Type', 'application/json')
        .query({ user, account })
        .then(logoutResp => {
          if (logoutResp?.body?.status === 'success') {
            cleanUpDOM();
            resp = dispatch(setLogoutSuccess());
            dispatch(appStateReset());
          } else {
            resp = dispatch(setLogoutError(logoutResp.data.message));
          }
          return resp;
        })
        .catch(err => {
          let errResp;
          if (err.response?.body?.data) {
            errResp = dispatch(setLogoutError(err.response.body.data));
          } else {
            errResp = dispatch(setLogoutError());
          }
          return errResp;
        });
    } else {
      cleanUpDOM();
      resp = Promise.resolve(dispatch(setLogoutSuccess()));
      dispatch(appStateReset());
    }

    return resp;
  };

export const resetState =
  (conf = {}) =>
  dispatch =>
    dispatch(appStateReset(conf));
