import type { RequiredDeep } from 'type-fest';

import type {
  Feed,
  FeedHealthCounts,
  FeedHealthDetail,
  FeedsHealth,
  FeedsHealthCounts,
  HealthDetailByServiceName,
  HealthStatus,
  HealthStatusBySystemComponent,
  Service,
  ServiceHealthCounts,
  ServiceHealthDetail,
  ServiceName,
  ServicesHealth,
} from '@models';
import { requiredServiceNames, serviceNames } from '@models';

import { isServiceOrphaned, isServiceUp } from './services';

// --- Factories ---------------------------------------------------------------

export const createFeedHealthCounts = (
  totalGroupsCount = 0,
): FeedHealthCounts => ({
  totalGroupsCount,
  enabledGroupsCount: 0,
  syncedGroupsCount: 0,
  emptyGroupsCount: 0,
});

export const createFeedHealthDetail = (
  totalGroupsCount = 0,
): FeedHealthDetail => ({
  ...createFeedHealthCounts(totalGroupsCount),
});

export const createFeedsHealthCounts = (totalCount = 0): FeedsHealthCounts => ({
  ...createFeedHealthCounts(),
  totalCount,
  enabledCount: 0,
});

export const createFeedsHealth = (): FeedsHealth => ({
  ...createFeedsHealthCounts(),
  hasFeeds: false,
  isFullySynced: false,
  detailByName: null,
});

export const createServiceHealthCounts = (
  instancesCount = 0,
): ServiceHealthCounts => ({
  instancesCount,
  upCount: 0,
  downCount: 0,
  orphanedCount: 0,
});

export const createServiceHealthDetail = (
  instancesCount = 0,
): ServiceHealthDetail => ({
  ...createServiceHealthCounts(instancesCount),
});

export const createServicesHealth = (): ServicesHealth => ({
  ...createServiceHealthCounts(),
  requiredUpCount: 0,
  hasAllRequiredServices: false,
  hasSomeServiceIssues: false,
  detailByName: Object.fromEntries(
    serviceNames.map(name => [name, createServiceHealthCounts()]),
  ) as HealthDetailByServiceName,
});

// --- Parsers -----------------------------------------------------------------

/**
 * Parses feed metadata and returns a detailed health object, which is used to
 * provide health insights and also informs the overall system status.
 */
export const getFeedsHealth = (feeds: Feed[] = []): FeedsHealth => {
  const totalCounts = createFeedsHealthCounts(feeds.length);

  const collectCounts = (detail: FeedHealthDetail) => {
    totalCounts.totalGroupsCount += detail.totalGroupsCount;
    totalCounts.enabledGroupsCount += detail.enabledGroupsCount;
    totalCounts.syncedGroupsCount += detail.syncedGroupsCount;
    totalCounts.emptyGroupsCount += detail.emptyGroupsCount;
  };

  const enabledFeeds = feeds.filter(
    feed => feed.enabled,
  ) as RequiredDeep<Feed>[];

  totalCounts.enabledCount = enabledFeeds.length;

  const detailByName = enabledFeeds.length
    ? enabledFeeds.reduce((result, feed) => {
        const feedDetail = feed.groups.reduce<FeedHealthDetail>(
          (detail, group) => {
            // Skip disabled groups
            if (!group?.enabled) return detail;

            detail.enabledGroupsCount += 1;

            if (group.lastSync) {
              if (!group.recordCount) detail.emptyGroupsCount += 1;

              detail.syncedGroupsCount += 1;
            }

            return detail;
          },
          createFeedHealthDetail(feed.groups?.length),
        );

        collectCounts(feedDetail);

        return {
          ...result,
          [feed.name]: feedDetail,
        };
      }, {})
    : null;

  const hasFeeds = !!(
    totalCounts.enabledCount && totalCounts.enabledGroupsCount
  );

  const isFullySynced = !!(
    hasFeeds &&
    totalCounts.syncedGroupsCount &&
    !totalCounts.emptyGroupsCount
  );

  return {
    ...totalCounts,
    hasFeeds,
    isFullySynced,
    detailByName,
  };
};

/**
 * Parses services metadata and returns a detailed health object, which is used
 * to provide health insights and also informs the overall system status.
 */
export const getServicesHealth = (services: Service[] = []): ServicesHealth => {
  const totalCounts = {
    ...createServiceHealthCounts(services.length),
    requiredUpCount: 0,
  };

  const collectCounts = (
    serviceName: ServiceName,
    detail: ServiceHealthDetail,
  ) => {
    totalCounts.upCount += detail.upCount;
    totalCounts.downCount += detail.downCount;
    totalCounts.orphanedCount += detail.orphanedCount;

    if (requiredServiceNames.includes(serviceName) && detail.upCount) {
      totalCounts.requiredUpCount += 1;
    }
  };

  const detailByName = serviceNames.reduce<HealthDetailByServiceName>(
    (result, serviceName) => {
      // There may be multiple instances of a service
      const instances = services.filter(
        state => state.serviceName === serviceName,
      );

      const serviceDetail = instances.reduce((detail, instance) => {
        switch (true) {
          case isServiceUp(instance):
            detail.upCount += 1;
            break;

          case isServiceOrphaned(instance):
            detail.orphanedCount += 1;
            break;

          // Must be down if not up or orphaned
          default:
            detail.downCount += 1;
        }

        return detail;
      }, createServiceHealthDetail(instances.length));

      collectCounts(serviceName, serviceDetail);

      return {
        ...result,
        [serviceName]: serviceDetail,
      };
    },
    {} as HealthDetailByServiceName,
  );

  // Check that all required services have at least one instance up
  const hasAllRequiredServices =
    // Fail fast - Check that the total up count meets the minimum required
    totalCounts.upCount >= requiredServiceNames.length &&
    // If we pass the short circuit, check the up count on each required service
    Object.entries(detailByName)
      .filter(([serviceName]) =>
        requiredServiceNames.includes(serviceName as ServiceName),
      )
      .every(([, { upCount }]) => upCount > 0);

  // Check if any service/instance is down.
  // Ignore orphaned instances as they are not critical.
  const hasSomeServiceIssues = totalCounts.downCount > 0;

  return {
    ...totalCounts,
    detailByName,
    hasAllRequiredServices,
    hasSomeServiceIssues,
  };
};

// --- Statuses ----------------------------------------------------------------

/**
 * Returns a single health status representing all feeds
 */
export const getFeedsStatus = ({
  isFullySynced,
  hasFeeds,
  syncedGroupsCount,
}: FeedsHealth): HealthStatus => {
  if (!hasFeeds || !syncedGroupsCount) return 'unhealthy';

  // TODO: Consider marking as degraded if it's likely that feed data is stale (i.e. last sync is very old or failed)
  if (!isFullySynced) return 'degraded';

  return 'healthy';
};

/**
 * Returns a single health status representing all services
 */
export const getServicesStatus = ({
  hasAllRequiredServices,
  hasSomeServiceIssues,
}: ServicesHealth): HealthStatus => {
  if (!hasAllRequiredServices) return 'unhealthy';

  // TODO: Maybe also check resource limits
  if (hasSomeServiceIssues) return 'degraded';

  return 'healthy';
};

/**
 * Derives the overall system status based on statuses of individual components.
 * If any component is unhealthy, the system is marked unhealthy as this usually
 * indicates that a required part of the system is missing or malfunctioning.
 */
export const getSystemStatus = (
  statusByComponent: HealthStatusBySystemComponent,
): Exclude<HealthStatus | undefined, 'degraded'> => {
  const statuses = Object.values(statusByComponent);

  // If any component is unhealthy, the system is unhealthy
  if (statuses.includes('unhealthy')) return 'unhealthy';

  // Wait for all component statuses to be set before settling the system status
  if (statuses.includes(undefined)) return undefined;

  return 'healthy';
};
